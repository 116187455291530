<template>
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 414 424"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        :fill="fill"
        d="M207,17C102.62,17,18,101.62,18,206s84.62,189,189,189s189-84.62,189-189S311.38,17,207,17z M207,336
		c-71.79,0-130-58.21-130-130S135.21,76,207,76s130,58.21,130,130S278.79,336,207,336z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconCircle',
  props: {
    fill: { default: '#00305a' },
  },
}
</script>
