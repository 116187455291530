<template>
    <svg id="_--icon_porcentagem" data-name="--icon_porcentagem" xmlns="http://www.w3.org/2000/svg" width="38.863" height="38.849" viewBox="0 0 38.863 38.849">
        <path id="Caminho_2124" data-name="Caminho 2124" d="M38.478.627a1.823,1.823,0,0,0-2.579,0L.7,35.828a1.824,1.824,0,1,0,2.579,2.579l35.2-35.2A1.823,1.823,0,0,0,38.478.627Z" transform="translate(-0.151 -0.092)" :fill="fill"/>
        <path id="Caminho_2125" data-name="Caminho 2125" d="M8.664.256A8.664,8.664,0,1,0,17.328,8.92,8.674,8.674,0,0,0,8.664.256Zm0,13.68A5.016,5.016,0,1,1,13.68,8.92,5.022,5.022,0,0,1,8.664,13.936Z" transform="translate(0 -0.244)" :fill="fill"/>
        <path id="Caminho_2126" data-name="Caminho 2126" d="M292.376,283.6a8.664,8.664,0,1,0,8.664,8.664A8.674,8.674,0,0,0,292.376,283.6Zm0,13.68a5.016,5.016,0,1,1,5.016-5.016A5.022,5.022,0,0,1,292.376,297.276Z" transform="translate(-262.177 -262.077)" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'IconPercent',
    props: {
        fill: {
            type: String,
            default: '#f4f4f4'
        }
    }
}
</script>
