<!-- Template -->

<template>
  <main class="p-home">
    <!-- Chamada de simulação -->
    <section class="p-home__container">
      <article class="p-home__container__simulate">
        <div class="p-home__container__simulate__info">
          <h1 class="p-home__container__simulate__info__title">
            ALFA EMPRÉSTIMO<br/> CONSIGNADO
          </h1>

          <p class="p-home__container__simulate__info__description">
            <b>Crédito para todas as<br /> horas com</b>
            segurança<br /> e rapidez
          </p>

          <a class="p-home__container__simulate__info__button" href="/simulador-de-emprestimo">
            Simule agora
          </a>
        </div>

        <div class="p-home__container__simulate__picture">
          <img class="p-home__container__simulate__picture__img" src="@/assets/img/home/person_02.png" />
          <IconDots class="p-home__container__simulate__picture__dots" fill="#ffb500" />
          <IconHalfCircle class="p-home__container__simulate__picture__half-circle" fill="#ffb500" />
          <IconCircle class="p-home__container__simulate__picture__circle" fill="#00305a" />
          <div class="p-home__container__simulate__picture__bar" />
        </div>
      </article>
    </section>

    <!-- O que é empréstimo consignado -->
    <section class="p-home__container">
      <article class="p-home__container__about">
        <img class="p-home__container__about__img" src="@/assets/img/home/img_question.png" />

        <div class="p-home__container__about__info">
          <h2 class="p-home__container__about__info__title">
            O que é empréstimo consignado?
          </h2>

          <p class="p-home__container__about__info__description">
            O Alfa empréstimo consignado é uma opção prática e segura de empréstimo, sendo o valor contratado 
            pago em parcelas mensais fixas, descontadas diretamente em folha de pagamento do contratante.
          </p>

          <span class="p-home__container__about__info__description">
            A contratação pode ser realizada de forma 100% digital ou com algum de nossos consultores.
          </span>
        </div>
      </article>
    </section>

    <!-- Características do consignado -->
    <section class="p-home__container">
      <ul class="p-home__container__details">
        <li class="p-home__container__details__detail">
          <div class="p-home__container__details__detail__icon">
            <IconPercent fill="#00305a" />
          </div>

          <div class="p-home__container__details__detail__description">
            <h3>Juros baixos</h3>

            <span>Taxas menores comparadas a outros empréstimos</span>
          </div>
        </li>

        <li class="p-home__container__details__detail">
          <div class="p-home__container__details__detail__icon">
            <IconCalendar2 fill="#00305a" />
          </div>

          <div class="p-home__container__details__detail__description">
            <h3>Prazos flexíveis</h3>

            <span>Flexibilidade no parcelamento e prazos de pagamento</span>
          </div>
        </li>

        <li class="p-home__container__details__detail">
          <div class="p-home__container__details__detail__icon">
            <IconCalculator fill="#00305a" />
          </div>

          <div class="p-home__container__details__detail__description">
            <h3>Facilidade</h3>

            <span>Desconto direto na folha de pagamento, sem burocracia</span>
          </div>
        </li>

        <li class="p-home__container__details__detail">
          <div class="p-home__container__details__detail__icon">
            <IconGlobe fill="#00305a" />
          </div>

          <div class="p-home__container__details__detail__description">
            <h3>Praticidade</h3>

            <span>Solicite de onde estiver, sem conta na instituição</span>
          </div>
        </li>
      </ul>
    </section>

    <!-- Requisitos para o emprestimo consignado -->
    <section class="p-home__container">
      <article class="p-home__container__conditions">
        <h2 class="p-home__container__conditions__title">
          Quem pode pedir o crédito consignado?
        </h2>

        <p class="p-home__container__conditions__subtitle">
          O Alfa Empréstimo Consignado é direcionado para diferentes públicos, sempre com o objetivo de oferecer 
          as melhores condições na hora de organizar as finanças.
        </p>

        <ul class="p-home__container__conditions__list">
          <li class="p-home__container__conditions__list__item">
            <h3 class="p-home__container__conditions__list__item__title">
              Militar e Forças Armadas
            </h3>

            <p class="p-home__container__conditions__list__item__description__extended">
              Empréstimo Consignado para Militares do Exército, da Marinha e da Aeronáutica
            </p>
          </li>

          <li class="p-home__container__conditions__list__item">
            <h3 class="p-home__container__conditions__list__item__title">
              Servidor Federal SIAPE
            </h3>

            <p class="p-home__container__conditions__list__item__description__extended">
              Empréstimo Consignado rápido e seguro para Servidores Federais
            </p>
          </li>

          <li class="p-home__container__conditions__list__item">
            <h3 class="p-home__container__conditions__list__item__title">
              Servidor Público Municipal e Estadual
            </h3>

            <p class="p-home__container__conditions__list__item__description__extended">
              Empréstimo Consignado para Servidores Públicos com parcelas descontadas diretamente da folha de 
              pagamento
            </p>
          </li>

          <li class="p-home__container__conditions__list__item">
            <h3 class="p-home__container__conditions__list__item__title">
              Aposentados e Pensionistas do INSS
            </h3>

            <p class="p-home__container__conditions__list__item__description__extended">
              Empréstimo Consignado para aposentados e pensionistas do INSS com prazo de até 84 meses
            </p>
          </li>

          <li class="p-home__container__conditions__list__item">
            <h3 class="p-home__container__conditions__list__item__title">
              Empresa Privada
            </h3>

            <p class="p-home__container__conditions__list__item__description__extended">
              Empréstimo Consignado para funcionários de empresa privada conveniada
            </p>
          </li>

          <li class="p-home__container__conditions__list__item">
            <h3 class="p-home__container__conditions__list__item__title">
              Servidor Legislativo e Judiciário
            </h3>

            <p class="p-home__container__conditions__list__item__description__extended">
              Empréstimo Consignado para órgãos do Poder Judiciário e Legislativo com segurança e rapidez
            </p>
          </li>
        </ul>
      </article>
    </section>

    <!-- Como solicitar empréstimo consignado -->
    <section class="p-home__container">
      <article class="p-home__container__solicitation">
        <div class="p-home__container__solicitation__info">
          <h2 class="p-home__container__solicitation__info__title">
            Como solicitar um <br />empréstimo consignado?
          </h2>

          <div class="p-home__container__solicitation__info__description">
            <p class="p-home__container__solicitation__info__description">
              É possível contratar o <strong>Empréstimo Consignado Alfa</strong> de onde você estiver. O processo 
              é simples: realize seu pré-cadastro no nosso site; <strong>faça uma simulação e, para finalizar, 
              siga com a contratação.</strong>
            </p>

            <strong>É rápido, fácil, seguro e sem burocracia!</strong>
          </div>

          <a class="p-home__container__solicitation__info__button" href="/vantagens-e-diferenciais">
            Conheça nossas vantagens
          </a>
        </div>

        <div class="p-home__container__solicitation__picture">
          <img class="p-home__container__solicitation__picture__img" src="@/assets/img/home/person_01.png" />

          <IconFloatItems class="p-home__container__solicitation__picture__svg" fill="$alfa-default-text" />
        </div>
      </article>
    </section>

    <!-- Banner final da página -->
    <section class="p-home__container">
      <article class="p-home__container__banner">
        <picture>
          <source media="(max-width: 768px)" srcset="@/assets/img/institucional-home-banner-m.png" />

          <img src="@/assets/img/institucional-home-banner-d.png" />
        </picture>
      </article>
    </section>
  </main>
</template>

<!-- Scripts -->

<script>
// Icones
import IconPercent from '~/assets/icons/IconPercent.vue'
import IconCalendar2 from '@/assets/icons/IconCalendar2.vue'
import IconCalculator from '@/assets/icons/IconCalculator.vue'
import IconGlobe from '~/assets/icons/IconGlobe.vue'
import IconFloatItems from '~/assets/icons/IconFloatItems.vue'
import IconDots from '@/assets/icons/IconDots.vue'
import IconHalfCircle from '~/assets/icons/IconHalfCircle.vue'
import IconCircle from '@/assets/icons/IconCircle.vue'

// Componente
export default {
  // Nome do componente
  name: 'HomePage',
  // Componentes importados
  components: {
    IconPercent,
    IconCalendar2,
    IconCalculator,
    IconGlobe,
    IconFloatItems,
    IconDots,
    IconHalfCircle,
    IconCircle,
  },
  // Layout padrão da página
  layout: 'default',
  setup() {
    definePageMeta({
      title: 'Contratar um empréstimo é fácil',
      description: `
        O Alfa está pronto para realizar seus sonhos com taxas competitivas para facilitar a contratação do seu 
        empréstimo consignado digital. E o melhor de tudo: 100% online!
      `,
      keywords: `
        empréstimo consignado; empréstimo consignado digital; alfa empréstimo consignado; alfa consignado; alfa 
        empréstimos; empréstimo online; emrpéstimo digital
      `
    })
  },
}
</script>

<!-- Estilos -->

<style lang="scss" scoped>
.p-home {
  display: grid;
  grid-auto-flow: row;
  place-content: start stretch;

  &__container {
    position: relative;
    display: grid;
    place-content: start center;
    justify-items: center;

    &:nth-child(1) {
      background-color: #0391a2;
      background-image: url('@/assets/img/home/bg_05.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 100% 85%;

      @media screen and (max-width: 1279px) {
        background-image: none;
        padding: 0;
      }
    }

    &:nth-child(2) {
      background-color: #f0f2f3;
    }

    &:nth-child(3) {
      background-color: #f0f2f3;
    }

    &:nth-child(4) {
      background-color: $alfa-default-text;
    }

    &:nth-child(5) {
      background-color: #f0f2f3;
    }

    &:nth-child(6) {
      background-color: #00c5d724;
    }

    // 1. Chamada de simulação sobre o empréstimo consignado
    &__simulate {
      display: grid;
      grid-template-columns: 43% 57%;
      place-items: start center;
      width: 90%;
      max-width: 1745px;

      @media screen and (max-width: 1279px) {
        grid-template-columns: auto;
        max-width: 1850px;
        padding: 0;
        padding-bottom: 195px;
        width: 100%;
      }

      @media (min-width: 1930px) {
        max-width: 1920px;
        grid-template-columns: 41% 57%;
        place-items: start center;
        width: 85%;
      }

      &__info {
        display: grid;
        grid-auto-flow: row;
        place-content: center;
        height: 85%;
        row-gap: 30px;

        @media screen and (max-width: 1279px) {
          grid-row: 2 / 3;
          padding: 0 0 31px;
          row-gap: 15px;
        }

        &__title {
          position: relative;
          padding-bottom: 20px;
          @include font($montserrat-semi-bold, 25, 3.5502vw, 65);
          // font-size: 65px;
          line-height: normal;
          color: #ffffff;

          &::before {
            z-index: 2;
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: block;
            width: 40%;
            height: 3px;
            border-radius: 5px;
            background-color: #7eeff9;
          }

          @media (max-width: 1600px) {
            @include font($montserrat-semi-bold, 25, 3.5vw, 50);
            color: $alfa-white;
            line-height: normal;
          }

          @media (max-width: 1380px) {
            line-height: normal;
            color: #ffffff;
          }

          @media screen and (max-width: 1279px) {
            text-align: center;
            padding: 0 10%;

            &::before {
              display: none;
            }
          }
        }

        &__description {
          font-family: $montserrat-regular;
          font-size: 52px;
          line-height: 3.4rem;
          color: #ffffff;

          b {
            font-family: $montserrat-bold;
          }

          @media screen and (max-width: 1600px) {
            @include font($montserrat-regular, 22, 2.4rem, 50);
            line-height: normal;
            color: #ffffff;
          }

          @media screen and (max-width: 1380px) {
            @include font($montserrat-regular, 22, 2.8046rem, 32);
            line-height: normal;
            color: #ffffff;
          }

          @media screen and (max-width: 1279px) {
            text-align: center;
            padding: 0 10%;
            @include font($montserrat-regular, 22, 2.5vw, 50, #fff, center);

            br {
              display: none;
            }

            b {
              font-family: $montserrat-regular;
              font-weight: normal;
            }
          }
        }

        &__button {
          place-self: start;
          cursor: pointer;
          padding: 22px 128px;
          text-decoration: none;
          text-align: center;
          @include font($montserrat-extra-bold, 18, 2.5vw, 50);
          line-height: normal;
          color: $alfa-default-text;
          outline: none;
          background-color: $alfa-third;
          border: 0;
          border-radius: 100px;

          @media screen and (max-width: 1600px) {
            @include font($montserrat-extra-bold, 18, 2vw, 28);
            padding: 11px 80px;
          }

          @media (max-width: 1279px) {
            place-self: center;
            text-transform: capitalize;
          }

          &:hover {
            background-color: #ffd66c;
          }
        }
      }

      &__picture {
        position: relative;
        display: grid;
        place-items: start center;
        justify-content: center;
        top: -25px;

        @media screen and ((min-width: 600px) and (max-width: 1279px)) {
          grid-row: 1 / 2;
          // overflow: hidden;
          // width: 50%;
          max-width: 570px;
          margin-bottom: 24px;
        }

        @media (max-width: 599px) {
          overflow: hidden;
          margin-bottom: 24px;
        }

        &__img {
          z-index: 2;
          width: 100%;

          @media (max-width: 599px) {
            width: 125%;
            position: relative;
            left: -10px;
            max-width: 570px;
          }
        }

        &__dots {
          z-index: 1;
          position: absolute;
          top: 40%;
          right: 0;
          width: 15%;

          @media screen and (max-width: 1279px) {
            top: 30%;
            right: 3%;
            width: 15%;
          }
        }

        &__half-circle {
          z-index: 1;
          position: absolute;
          top: 42%;
          left: 5%;
          width: 35%;
        }

        &__circle {
          z-index: 1;
          position: absolute;
          bottom: -22%;
          right: -9%;
          width: 65%;

          @media (max-width: 1279px) {
            right: -2%;
            bottom: -1%;
            max-width: 300px;
          }

          @media (max-width: 600px) {
            right: -15%;
          }

          @media (min-width: 1930px) {
            right: -13%;
          }
        }

        &__bar {
          height: 1px;
          width: 80%;
          background: yellow;
        }
      }
    }

    // 2. Informações sobre o empréstimo consignado
    &__about {
      z-index: 2;
      margin-top: -175px;
      display: grid;
      grid-template-columns: 265px auto;
      place-content: start center;
      place-items: center;
      width: 90%;
      max-width: 1745px;
      padding: 48px 166px 48px 37px;
      background-color: #ffffff;
      border-radius: 24px;
      box-shadow: 4px 4px 20px #00000029;
      gap: 40px;

      @media (min-width: 1700px) {
        margin-top: -197px;
      }

      @media (min-width: 1280px) and (max-width: 1400px) {
        margin-top: -155px;
      }

      @media screen and (max-width: 1279px) {
        grid-template-columns: 1fr;
        margin-top: -193px;
        padding: 26px 24px 40px;
        width: 90%;
        left: auto;
      }

      @media (min-width: 1930px) {
        max-width: 1800px;
      }

      &__img {
        width: 100%;

        @media screen and (max-width: 1279px) {
          width: 200px;
          height: 220px;
        }
      }

      &__info {
        display: grid;
        grid-auto-flow: row;
        grid-template-rows: auto;
        row-gap: 40px;

        @media screen and (max-width: 1600px) {
          row-gap: 20px;
        }

        &__title {
          @include font($montserrat-semi-bold, 28, 2.3333vw, 44);
          color: $alfa-default-text;

          @media screen and (max-width: 1279px) {
            text-align: center;
          }
        }

        &__description {
          line-height: normal;
          @include font($montserrat-regular, 18, 1.7396vw, 25);
          color: $alfa-default-text;

          @media screen and (max-width: 1279px) {
            text-align: center;
          }
        }

        span {
          font-family: $montserrat-semi-bold;
        }
      }
    }

    // 3. Detalhes sobre o empréstimo consignado
    &__details {
      display: grid;
      grid-auto-flow: column;
      place-items: start center;
      padding: 88px 0;
      width: 91%;

      @media screen and (max-width: 1440px) {
        padding: 88px 0 70px;
      }

      @media screen and (max-width: 1279px) {
        max-width: 1920px;
        overflow-x: auto;
        padding: 60px 40px;
        width: 100%;
        scroll-snap-type: x mandatory;
      }

      @media (min-width: 1930px) {
        max-width: 1920px;
        width: 93%;
      }

      &__detail {
        display: grid;
        grid-template-rows: 166px 256px;
        border-radius: 40px;
        max-width: 375px;
        box-shadow: 0px 3px 6px #00000029;
        overflow: hidden;

        @media screen and (max-width: 1440px) {
          grid-template-rows: 116px 159px;
        }

        @media screen and (max-width: 1279px) {
          width: 300px;
          grid-template-rows: 140px 175px;
          scroll-snap-align: center;
        }

        @media screen and (max-width: 700px) {
          width: 240px;
          grid-template-rows: 120px 169px;
        }

        &:not(:first-of-type) {
          margin-left: 80px;

          @media screen and (max-width: 1540px) {
            margin-left: 60px;
          }

          @media screen and (max-width: 1250px) {
            margin-left: 40px;
          }
        }

        &__icon {
          display: grid;
          place-content: center;
          background-color: $alfa-secondary;

          svg {
            width: 81px;
            height: 81px;

            @media screen and (max-width: 1440px) {
              width: 70px;
              height: 70px;
            }

            @media (max-width: 1279px) {
              width: 55px;
              height: 55px;
            }
          }
        }

        &__description {
          display: grid;
          place-content: start center;
          row-gap: 30px;
          background-color: #7eeff9;
          padding: 24px 35px;

          @media screen and (max-width: 1440px) {
            row-gap: 15px;
            padding: 20px 22px;
          }

          @media (max-width: $tablet) {
            padding: 20px 15px 30px;
          }

          h3 {
            @include font($montserrat-bold, 20, 1.4731vw, 25);
            line-height: normal;
            text-align: center;
            color: $alfa-default-text;

            @media (max-width: 1440px) {
              @include font($montserrat-bold, 18, 1.0938vw, 28);
              line-height: normal;
              text-align: center;
            }

            @media (max-width: 1279px) {
              @include font($montserrat-bold, 20, 1.4731vw, 25);
              line-height: normal;
              text-align: center;
              color: $alfa-default-text;
            }
          }

          span {
            @include font($montserrat-regular, 18, 1.4731vw, 25);
            color: $alfa-default-text;
            text-align: center;

            @media (max-width: 1440px) {
              @include font($montserrat-regular, 16, 1.0938vw, 24);
              text-align: center;
            }

            @media (max-width: 1279px) {
              @include font($montserrat-regular, 18, 1.4731vw, 25);
              color: $alfa-default-text;
              text-align: center;
            }
          }
        }
      }
    }

    // 4. Condições de empréstimo consignado
    &__conditions {
      display: grid;
      grid-auto-flow: row;
      place-items: start center;
      row-gap: 32px;
      max-width: 1920px;
      padding: 74px 140px 72px;

      @media screen and (max-width: 1735px) {
        padding: 74px 60px 72px;
      }

      @media screen and (max-width: 1279px) {
        padding: 60px 18px;
      }

      @media (min-width: 1920px) {
        max-width: 1920px;
      }

      &__title {
        text-align: center;
        font-family: $montserrat-semi-bold;
        font-size: clamp(1.75rem, 3rem, 2.875rem);
        color: #ffffff;

        @media screen and (max-width: 1279px) {
          @include font($montserrat-semi-bold, 28, 2.5vw, 40, #fff, center);
        }
      }

      &__subtitle {
        text-align: center;
        font-family: $montserrat-regular;
        font-size: clamp(1.125rem, 2.5rem, 1.5625rem);
        line-height: 2.5rem;
        color: #ffffff;

        @media (max-width: 1279px) {
          @include font($montserrat-regular, 18, 2.5vw, 25, #fff, center);
        }
      }

      &__list {
        display: flex;
        column-gap: 16px;
        row-gap: 95px;
        margin: 0;
        padding: 50px 0 25px;
        list-style-type: none;
        flex-wrap: wrap;
        justify-content: center;

        @media screen and (min-width: 1279px) {
          column-gap: 16px;
          row-gap: 95px;
          margin: 0;
          padding: 80px 0 25px;
        }

        &__item {
          display: flex;
          background-color: #ffffff;
          border-radius: 5px;
          flex-direction: column;
          max-width: 530px;

          @media (min-width: 1280px) {
            width: calc(33.33% - 20px);
          }

          &__title {
            width: 456px;
            padding: 24px;
            @include font($montserrat-semi-bold, 18, 1.1144vw, 20);
            line-height: normal;
            color: $alfa-default-text;
            background-color: $alfa-qui;
            border-radius: 5px;
            box-shadow: 0 3px 6px #245aa44d;
            text-align: center;
            margin: -38px auto;
            z-index: 1;

            @media screen and (max-width: 1735px) {
              width: 90%;
              text-align: center;
              padding: 24px 16px;
            }

            @media (max-width: 1380px) {
              @include font($montserrat-semi-bold, 14, 1.1vw, 20, $alfa-default-text, center);
            }

            @media screen and (max-width: 1279px) {
              background-color: $alfa-apoio-1;
              @include font($montserrat-semi-bold, 22, 1.1144vw, 28, $alfa-default-text, center);
            }
          }

          &__description {
            padding: $spacing_64 $spacing_32 $spacing_32;
            @include font($montserrat-regular, 18, 1.3237vw, 22, $alfa-default-text, center);
            height: 200px;

            @media (max-width: 1380px) {
              @include font($montserrat-regular, 14, 1.1vw, 22, $alfa-default-text, center);
            }

            @media screen and (max-width: 1279px) {
              @include font($montserrat-regular, 18, 1vw, 22, $alfa-default-text, center);
              height: 100%;
            }

            @media (max-width: 360px) {
              padding: 80px $spacing_32 $spacing_32;
            }

            &__extended {
              padding: $spacing_64 $spacing_32 $spacing_32;
              @include font($montserrat-regular, 18, 1.3237vw, 22, $alfa-default-text, center);
              background-color: #ffffff;
              border-radius: 5px;

              @media (max-width: 1380px) {
                @include font($montserrat-regular, 14, 1.1vw, 22, $alfa-default-text, center);
              }

              @media (max-width: 500px) {
                @include font($montserrat-regular, 18, 1vw, 22, $alfa-default-text, center);
                padding: 64px $spacing_32 $spacing_32;
              }
            }
          }
        }
      }
    }

    // 5. Solicitação de crédito
    &__solicitation {
      display: grid;
      grid-template-columns: 1fr 1fr;
      place-items: center center;
      max-width: 1600px;
      padding: 0 100px;

      @media (max-width: 1650px) {
        padding: 0;
        margin: 0 100px;
        padding-right: 80px;
      }

      @media screen and (max-width: 1279px) {
        grid-template-columns: auto;
        max-width: 1850px;
        padding: 48px 43px 47px;
        margin: 0;
        gap: 24px;
      }

      @media (min-width: 1930px) {
        max-width: 1920px;
        padding: 0;
        width: 85%;
      }

      &__info {
        display: grid;
        grid-auto-flow: row;
        place-content: start stretch;
        row-gap: 35px;
        padding: 120px 0 198px;

        @media screen and (max-width: 1279px) {
          grid-row: 2 / 3;
          padding: 24px 0 47px;
        }

        @media (max-width: 1400px) {
          align-content: center;
          padding: 0;
        }

        &__title {
          @include font($montserrat-semi-bold, 28, 2.6442vw, 46);
          line-height: normal;
          color: $alfa-default-text;

          @media screen and (max-width: 1279px) {
            // @include font($montserrat-semi-bold, 28, 2.5vw, 40);
            text-align: center;
          }
        }

        &__description {
          @include font($montserrat-regular, 18, 1.4731vw, 25);
          line-height: normal;
          color: $alfa-default-text;

          &:nth-child(1) {
            margin-bottom: 20px;
          }

          @media (max-width: 1279px) {
            @include font($montserrat-regular, 18, 2.5vw, 25);
          }

          strong {
            font-family: $montserrat-semi-bold;
          }

          @media screen and (max-width: 1279px) {
            text-align: center;

            br {
              display: none;
            }
          }
        }

        &__button {
          place-self: start;
          cursor: pointer;
          padding: 20px 70px;
          text-decoration: none;
          text-align: center;
          @include font($montserrat-extra-bold, 18, 1.5725vw, 27);
          color: $alfa-default-text;
          outline: none;
          background-color: $alfa-third;
          border: 0;
          border-radius: 100px;

          @media screen and (max-width: 1279px) {
            place-self: center;
            padding: 11px 20px;
            @include font($montserrat-extra-bold, 17, 2.5vw, 25, $alfa-default-text, center);
            text-transform: capitalize;
          }

          &:hover {
            background-color: #ffd66c;
          }
        }
      }

      &__picture {
        position: relative;
        display: grid;
        place-items: start center;

        @media screen and (max-width: 1279px) {
          grid-row: 1 / 2;
          place-self: center;
          margin-right: 20px;
          max-width: 570px;

          &::before {
            z-index: 2;
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background-color: $alfa-default-text;
            border-radius: 5px;
          }
        }

        &__img {
          width: 100%;
          max-width: 664px;
        }

        &__svg {
          position: absolute;
          top: 23%;
          right: -15%;
          width: 46%;
          fill: $alfa-default-text;

          @media (max-width: 1279px) {
            width: 42%;
            top: 17%;
            right: -8%;
          }
        }
      }
    }

    // 6. Banner alterável
    &__banner {
      display: grid;
      place-content: stretch;
      max-width: 1920px;

      &__title {
        text-align: center;
        font-family: $montserrat-regular;
        font-size: 90px;
        color: #00305a;
        padding: 278px 0 228px;

        @media screen and (max-width: 1279px) {
          font-size: clamp(1.25rem, 2rem, 1.5625rem);
        }
      }

      & > picture > img {
        width: fit-content;
        height: fit-content;
        max-height: fit-content;
        object-fit: cover;
        object-position: center;
        max-width: 100%;
      }
    }
  }
}
</style>
 