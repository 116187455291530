<template>
  <svg
    version="1.1"
    id="ícones_flutuantes"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 305.4 429.5"
    xml:space="preserve"
  >
    <path
      id="_--icon_celular"
      class="st0"
      :fill="fill"
      d="M45.1,90.3C20.2,90.2,0,70,0,45.1C0,27,10.9,10.6,27.6,3.5c23-9.7,49.4,1,59.2,24
	s-1,49.4-24,59.2l0,0C57.1,89.1,51.2,90.3,45.1,90.3z M31.6,16.3c-2.7,0-4.8,2.2-4.9,4.9v48.5c0,2.7,2.2,4.8,4.9,4.9h27.2
	c2.7,0,4.8-2.2,4.9-4.9V21.2c0-2.7-2.2-4.8-4.9-4.9H31.6z M58.8,72.6H31.6c-1.6,0-2.9-1.3-2.9-2.9v-4.9h33v4.9
	C61.7,71.3,60.4,72.6,58.8,72.6C58.8,72.6,58.8,72.6,58.8,72.6z M45.2,65.8c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9
	c1.6,0,2.9-1.3,2.9-2.9c0,0,0,0,0,0C48.1,67.1,46.8,65.8,45.2,65.8z M45.2,69.7c-0.5,0-1-0.4-1-1s0.4-1,1-1c0.5,0,1,0.4,1,1
	C46.2,69.2,45.8,69.7,45.2,69.7L45.2,69.7z M61.7,62.9h-33V24.1h33V62.9z M61.7,22.1h-33v-1c0-1.6,1.3-2.9,2.9-2.9h27.2
	c1.6,0,2.9,1.3,2.9,2.9L61.7,22.1L61.7,22.1z M44.3,19.2c-0.5,0-1,0.5-0.9,1c0,0.5,0.4,0.9,0.9,0.9h1.9c0.5,0,1-0.5,0.9-1
	c0-0.5-0.4-0.9-0.9-0.9H44.3z"
    />
    <path
      id="_--icon_documento"
      class="st0"
      :fill="fill"
      d="M244.4,429.5c-33.7,0-61-27.3-60.9-61c0-24.5,14.7-46.6,37.2-56.1
	c31.1-13,66.8,1.6,79.8,32.6c13,31-1.5,66.6-32.4,79.7C260.6,427.9,252.6,429.6,244.4,429.5z M231.1,332.4c-1.5,0-3,0.6-4,1.7
	l-10.4,10.4c-1.1,1.1-1.7,2.5-1.7,4v49.4c0,3.2,2.6,5.7,5.7,5.7h43.1c3.2,0,5.7-2.6,5.7-5.7v-31.5l14.7-14.7
	c2.5-2.7,2.4-6.9-0.3-9.4c-2.6-2.4-6.6-2.4-9.1,0l-5.3,5.3v-9.4c0-3.2-2.6-5.7-5.7-5.7L231.1,332.4z M263.8,399.6h-43.1
	c-1,0-1.8-0.8-1.8-1.8v-49h10.6c1.1,0,2-0.9,2-2v-10.6h32.3c1,0,1.8,0.8,1.8,1.8v13.4l-20.9,20.9c-0.4,0.4-0.6,0.9-0.6,1.4v6.6
	c0,1.1,0.9,2,2,2h6.6c0.5,0,1-0.2,1.4-0.6l11.4-11.4v27.6h0C265.6,398.8,264.8,399.6,263.8,399.6z M225.3,390.5c-1.1,0-2,0.8-2,1.9
	c0,1.1,0.8,2,1.9,2c0,0,0.1,0,0.1,0h33.9c1.1,0,2-0.8,2-1.9c0-1.1-0.8-2-1.9-2c0,0-0.1,0-0.1,0H225.3z M225.3,378.2
	c-1.1,0-2,0.8-2,1.9c0,1.1,0.8,2,1.9,2c0,0,0.1,0,0.1,0h15c1.1,0,2-0.9,1.9-2c0-1.1-0.9-1.9-1.9-1.9H225.3z M225.3,366
	c-1.1,0-2,0.8-2,1.9s0.8,2,1.9,2c0,0,0.1,0,0.1,0h15c1.1,0,2-0.9,1.9-2c0-1.1-0.9-1.9-1.9-1.9H225.3z M225.3,353.8
	c-1.1,0-2,0.8-2,1.9s0.8,2,1.9,2c0,0,0.1,0,0.1,0h28.2c1.1,0,2-0.8,2-1.9c0-1.1-0.8-2-1.9-2c0,0-0.1,0-0.1,0H225.3z M237.4,341.5
	c-1.1,0-2,0.8-2,1.9c0,1.1,0.8,2,1.9,2c0,0,0.1,0,0.1,0h21.8c1.1,0,2-0.8,2-1.9s-0.8-2-1.9-2c0,0-0.1,0-0.1,0H237.4z M251.9,378.3
	L251.9,378.3L251.9,378.3h-3.8v-3.8l29.6-29.5c1.1-1,2.7-1,3.8,0c1.1,1.1,1.1,2.8,0,3.8L251.9,378.3z M227.6,344.9h-5.8l5.8-5.8
	V344.9L227.6,344.9z"
    />
    <path
      id="_--icon_mão-dinheiro"
      class="st0"
      :fill="fill"
      d="M218.3,270.4c-22.5,0-40.6-18.2-40.6-40.7c0-16.3,9.8-31,24.8-37.4
	c20.8-8.5,44.5,1.6,53,22.4c8.3,20.4-1.2,43.7-21.3,52.5C229.1,269.4,223.7,270.5,218.3,270.4z M191.1,228.3c-1.5,0-2.7,1.2-2.7,2.7
	v20.7c0,1.5,1.2,2.7,2.7,2.7h7.3c1.5,0,2.7-1.2,2.7-2.7v-1.6c1,0.2,4.7,1,8.8,1.9c2.9,0.6,5.4,1.1,7.2,1.4c1.1,0.2,2.1,0.4,2.8,0.5
	c0.6,0.1,1.2,0.2,1.8,0.2c0.4,0,0.7-0.1,1-0.2c0.8-0.2,1.6-0.6,2.3-0.9c4-2.1,7.8-4.4,11.4-7c3.1-2.1,6-4.1,8.1-5.5h0
	c0.7-0.5,1.2-0.9,1.7-1.2c1.2-0.8,1.9-2.2,1.8-3.6c0-2.5-2-4.5-4.5-4.5c-0.6,0-1.2,0.1-1.8,0.4c-1.3-1.7-3.6-2.2-5.5-1.2l-0.1,0
	l-1.8,1.1c-1.3-1.6-3.6-2.1-5.4-1.1l-0.1,0l-7,4.3c-1.9-0.6-3.6-1-5.1-1.5c-2-0.6-3.6-1-4.9-1.3c-0.7-0.2-1.3-0.3-1.8-0.4
	c-0.3-0.1-0.5-0.1-0.7-0.1c-0.2,0-0.4,0-0.6,0c-0.1,0-0.3,0-0.4,0c-0.5,0.1-1.1,0.2-2.1,0.4c-1.7,0.4-3.8,1-5.3,1.4V231
	c0-1.5-1.2-2.7-2.7-2.7H191.1z M215.1,219.5c-0.6,0-1,0.4-1,1c0,1.4,0.6,2.7,1.6,3.6c1,0.8,2.2,1.3,3.5,1.3v0.9c0,0.6,0.4,1,1,1
	s1-0.4,1-1v-0.9c1.1-0.1,2.2-0.4,3.1-1c0.6-0.4,1.2-0.9,1.5-1.6c0.4-0.7,0.6-1.5,0.5-2.3c0-0.8-0.2-1.7-0.6-2.4
	c-0.3-0.6-0.7-1.1-1.3-1.4c-0.9-0.6-1.9-1-2.9-1.3c-0.1,0-0.2,0-0.3-0.1l-0.1,0v-6.1c0.8,0.1,1.5,0.4,2.2,0.8
	c0.3,0.2,0.6,0.5,0.7,0.9c0.2,0.4,0.3,0.7,0.2,1.1c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1c0-0.7-0.1-1.4-0.4-2c-0.5-1-1.3-1.7-2.3-2.2
	c-0.8-0.4-1.6-0.6-2.5-0.7v-0.9c0-0.6-0.4-1-1-1s-1,0.4-1,1v0.8c-0.7,0-1.3,0.2-2,0.4c-1,0.4-1.9,1.1-2.5,2
	c-0.5,0.8-0.7,1.6-0.7,2.5c0,0.8,0.2,1.6,0.6,2.3c0.3,0.5,0.8,1,1.3,1.3c0.9,0.5,1.9,0.9,2.9,1.1c0.1,0,0.3,0.1,0.4,0.1v6.6
	c-0.8-0.1-1.6-0.4-2.2-0.8c-0.6-0.5-1-1.3-0.9-2.1C216.1,220,215.7,219.5,215.1,219.5C215.1,219.5,215.1,219.5,215.1,219.5z
	 M198.4,252.3h-7.3c-0.4,0-0.7-0.3-0.7-0.7V231c0-0.4,0.3-0.7,0.7-0.7h7.3c0.4,0,0.7,0.3,0.7,0.7v20.7
	C199.1,252,198.8,252.3,198.4,252.3z M221.8,252c-0.5,0-1-0.1-1.5-0.2c-2.8-0.4-7.9-1.4-11.8-2.3c-1.7-0.4-3.6-0.8-5.5-1.2l-1.9-0.4
	v-12.9l0.8-0.2c1.1-0.3,2.7-0.7,4.1-1.1c0.8-0.2,1.4-0.3,1.9-0.5c0.3-0.1,0.5-0.1,0.7-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0
	c0.5,0.1,1.7,0.3,3.1,0.7c4.6,1.2,11.9,3.4,13.4,3.9c0.8,0.2,1.6,0.6,2.3,1.2c0.3,0.2,0.5,0.5,0.6,0.8c0.1,0.3,0.2,0.7,0.2,1
	c0,1.6-1.3,2.8-2.8,2.8l-2.6-0.3l-8.8-1.1c0,0-0.1,0-0.1,0c-0.5,0-0.9,0.4-1,0.9c-0.1,0.5,0.3,1,0.9,1.1c0,0,0,0,0,0l8.8,1.1
	l2.7,0.3h0c2.7,0,4.8-2.2,4.8-4.8c0-0.6-0.1-1.3-0.4-1.9c-0.4-0.8-1-1.5-1.8-2c-0.7-0.5-1.5-0.9-2.4-1.1l-0.1,0h0
	c-0.5-0.1-1-0.3-1.6-0.5l5.4-3.3c0.3-0.2,0.7-0.3,1.1-0.3c0.6,0,1.2,0.2,1.7,0.7l-2.7,1.6c-0.5,0.3-0.6,0.9-0.4,1.4c0,0,0,0,0,0
	c0.3,0.5,0.9,0.6,1.4,0.4c0,0,0,0,0,0l6.2-3.7c1-0.5,2.1-0.3,2.9,0.5l-7.3,4.3c-0.5,0.3-0.6,0.9-0.4,1.4c0,0,0,0,0,0
	c0.3,0.5,0.9,0.6,1.4,0.4c0,0,0,0,0,0l7.8-4.6c0.1,0,0.2,0,0.2-0.1c0.2-0.1,0.3-0.2,0.4-0.3l0.2-0.1c0.3-0.2,0.7-0.3,1.1-0.3
	c1.4,0,2.5,1.1,2.5,2.5c0,0.8-0.3,1.5-1,2c-0.5,0.3-1.1,0.7-1.7,1.2c-2.7,1.9-6.7,4.7-10.7,7.3c-2.8,1.8-5.1,3.2-6.9,4.2
	c-0.8,0.5-1.7,0.9-2.6,1.3c-0.3,0.1-0.6,0.2-0.9,0.3C222.1,252,221.9,252,221.8,252L221.8,252z M221.3,223.4L221.3,223.4v-6
	c0.6,0.1,1.1,0.4,1.6,0.6c0.4,0.2,0.8,0.5,1.1,0.9c0.3,0.5,0.4,1,0.4,1.6c0,0.5-0.1,1-0.3,1.4c-0.1,0.3-0.3,0.5-0.6,0.7
	c-0.4,0.3-0.9,0.6-1.5,0.7C221.8,223.3,221.5,223.3,221.3,223.4L221.3,223.4z M219.3,214.8L219.3,214.8c-0.8-0.2-1.6-0.5-2.3-1
	c-0.3-0.2-0.5-0.4-0.6-0.7c-0.2-0.3-0.2-0.7-0.2-1.1c0-0.7,0.3-1.4,0.8-1.9c0.3-0.3,0.7-0.6,1.1-0.7c0.4-0.1,0.8-0.2,1.2-0.3
	L219.3,214.8L219.3,214.8z"
    />
    <path
      id="_--icon_saco-de-dinheiro"
      class="st0"
      :fill="fill"
      d="M159.2,170c-4,0-8-0.4-11.9-1.2c-7.6-1.5-14.8-4.6-21.1-8.9
	c-9.5-6.4-17-15.5-21.4-26c-1.5-3.6-2.7-7.3-3.4-11.1c-1.6-7.9-1.6-16,0-23.8c1.5-7.6,4.6-14.8,8.9-21.1c6.4-9.5,15.5-17,26-21.4
	c3.6-1.5,7.3-2.7,11.1-3.4c7.9-1.6,16-1.6,23.8,0c7.6,1.5,14.8,4.6,21.1,8.9c9.5,6.4,17,15.5,21.4,26c1.5,3.6,2.7,7.3,3.4,11.1
	c1.6,7.9,1.6,16,0,23.8c-1.5,7.6-4.6,14.8-8.9,21.1c-6.4,9.5-15.5,17-26,21.4c-3.6,1.5-7.3,2.7-11.1,3.4
	C167.2,169.6,163.2,170,159.2,170z M159.6,145.6L159.6,145.6c0.6,2.1,3,3.9,6.9,5.1c4.1,1.2,8.4,1.8,12.7,1.7c2.3,0,4.6-0.2,6.9-0.5
	c2.2-0.3,4.3-0.8,6.4-1.5c1.7-0.5,3.3-1.4,4.7-2.5c1.1-0.8,1.8-2,1.8-3.4v-33.8c-0.1-1.3-0.7-2.6-1.7-3.4c-1.3-1.1-2.9-1.9-4.5-2.5
	c-4.3-1.4-8.9-2.1-13.5-2c-3.2,0-6.3,0.3-9.5,0.9c-3.5-7.2-8.1-12.8-13.3-15.9l5.3-10.5l0,0c0.8-1.8,0.7-3.9-0.3-5.7
	c-1.1-1.7-2.9-2.7-4.8-2.7h-19.2c-2,0-3.8,1-4.8,2.7c-1,1.7-1.2,3.8-0.3,5.7l5.3,10.5c-4.1,2.8-7.6,6.4-10.3,10.6
	c-4.8,7.4-8,16.7-8,23.9c0,14.3,12.4,26,27.7,26h0.1C151.5,148.4,155.7,147.5,159.6,145.6L159.6,145.6z M179.2,150.1
	c-2.4,0-4.9-0.2-7.3-0.5c-1.9-0.3-3.7-0.7-5.5-1.4c-1.2-0.4-2.4-1-3.5-1.8c-0.7-0.4-1.1-1.1-1.2-1.8v-7.4c2,1.4,4.3,2.3,6.6,2.7
	c3.6,0.6,7.2,0.9,10.9,0.9c2.9,0,5.9-0.2,8.8-0.5c3.9-0.5,6.8-1.4,8.6-2.6v6.9c-0.1,0.8-0.6,1.4-1.2,1.8c-1.1,0.8-2.2,1.4-3.5,1.8
	c-1.8,0.6-3.6,1.1-5.5,1.4C184.1,150,181.7,150.2,179.2,150.1L179.2,150.1z M147.2,146.1H147c-1.7,0-3.4-0.2-5.1-0.5
	c-1.6-0.3-3.2-0.8-4.8-1.4c-1.5-0.6-2.9-1.3-4.3-2.2c-1.3-0.8-2.6-1.8-3.8-2.9c-1.1-1.1-2.2-2.2-3.1-3.5c-0.9-1.3-1.7-2.6-2.3-4
	c-1.3-2.9-2-6-2-9.2c0-6.7,3-15.6,7.6-22.6c1.9-3,4.2-5.7,6.9-8.1c3.5-3,7.2-4.5,10.9-4.5l0,0c2.2,0,4.3,0.5,6.2,1.5
	c2.1,1,4,2.3,5.6,3.9c1.8,1.7,3.4,3.5,4.8,5.5c1.4,2,2.7,4.1,3.8,6.3c-1.1,0.3-2.2,0.7-3.3,1.1c-0.9,0.4-1.8,0.9-2.6,1.4
	c-1.3,0.8-2.2,2.2-2.3,3.8v32.3C155.6,145.1,151.4,146.1,147.2,146.1L147.2,146.1L147.2,146.1z M137.6,121.3v0.5
	c0,1.3,0.2,2.5,0.6,3.7c0.5,1,1.1,1.9,1.8,2.7c0.8,0.7,1.8,1.3,2.8,1.6c0.9,0.4,1.9,0.6,2.9,0.7v2.4h2.9v-2.4
	c0.9-0.1,1.8-0.3,2.7-0.6c1-0.4,2-0.9,2.8-1.6c0.8-0.7,1.4-1.6,1.8-2.5c0.5-1.2,0.7-2.4,0.7-3.7c0-1.2-0.2-2.4-0.7-3.5
	c-0.5-0.9-1.2-1.6-2-2.3c-0.8-0.6-1.8-1.1-2.8-1.5l-0.7-0.3l-1.8-0.6V107c0.7,0,1.3,0.3,1.7,0.8c0.5,0.8,0.8,1.6,0.8,2.5v0.3h4.7
	v-0.3c0-1.1-0.2-2.2-0.7-3.2c-0.3-1-0.9-1.8-1.7-2.4c-0.7-0.6-1.6-1.1-2.5-1.3c-0.7-0.3-1.4-0.5-2.1-0.5h-0.2v-2.4h-2.8v2.4h-0.2
	c-0.8,0-1.5,0.2-2.2,0.5c-1,0.3-1.9,0.7-2.7,1.4c-0.8,0.6-1.4,1.4-1.8,2.3c-0.5,1-0.7,2-0.7,3.1c0,1.2,0.2,2.4,0.7,3.6
	c0.5,0.9,1.2,1.7,2,2.3c0.8,0.6,1.7,1.1,2.7,1.5l0.7,0.2l1.5,0.6v8c-1,0-1.9-0.5-2.4-1.3c-0.7-1-1-2.2-0.9-3.5v-0.3L137.6,121.3z
	 M179.2,138.5c-2.5,0-5-0.1-7.6-0.4c-1.9-0.2-3.7-0.6-5.5-1.1c-1.2-0.3-2.4-0.9-3.4-1.7c-0.7-0.6-1.1-1.4-1.1-2.3v-6.8
	c1.8,1.2,4.7,2,8.6,2.5c3,0.3,5.9,0.5,8.9,0.5c7.4,0,14.1-1.5,17.4-4v9c-0.1,0.7-0.5,1.3-1.1,1.6c-1,0.6-2.1,1.1-3.3,1.4
	C188,138.1,183.6,138.6,179.2,138.5L179.2,138.5z M179.2,126.8c-4.4,0.1-8.8-0.4-13.1-1.4c-1.2-0.3-2.3-0.8-3.3-1.4
	c-0.6-0.3-1-0.9-1.1-1.6v-7.9c3.4,2.5,10.3,4.2,17.5,4.2c3.4,0,6.7-0.3,10-1.1c2.7-0.5,5.2-1.6,7.5-3.1v6.7
	c-0.1,0.8-0.6,1.4-1.2,1.8c-1.1,0.8-2.2,1.4-3.5,1.8c-1.8,0.6-3.6,1.1-5.5,1.4C184.1,126.7,181.7,126.8,179.2,126.8L179.2,126.8z
	 M148.5,126.4L148.5,126.4v-7.3h0.2l0.6,0.2l1.3,0.7l0.8,1c0.2,0.5,0.4,1,0.3,1.5c0.1,1-0.3,2-1,2.8
	C150.1,125.9,149.4,126.3,148.5,126.4L148.5,126.4z M179.2,116.5c-2.4,0-4.9-0.2-7.3-0.6c-1.9-0.3-3.7-0.8-5.5-1.4
	c-1.3-0.4-2.4-1-3.5-1.8c-0.7-0.4-1.1-1.1-1.2-1.9c0.1-0.8,0.6-1.4,1.2-1.8c1.1-0.8,2.2-1.4,3.5-1.8c1.8-0.6,3.6-1.1,5.5-1.4
	c4.8-0.7,9.8-0.7,14.6,0c1.9,0.3,3.7,0.7,5.5,1.4c1.2,0.4,2.4,1,3.4,1.8c0.7,0.4,1.1,1.1,1.2,1.8c-0.1,0.8-0.6,1.4-1.2,1.9
	c-1.1,0.8-2.2,1.4-3.5,1.8c-1.8,0.6-3.6,1.1-5.5,1.4C184.1,116.3,181.7,116.5,179.2,116.5L179.2,116.5z M145.8,113.1L145.8,113.1
	l-0.2-0.1h-0.5l-1.2-0.7c-0.3-0.3-0.5-0.6-0.7-0.9c-0.2-0.4-0.4-0.8-0.3-1.3c-0.1-0.9,0.3-1.8,0.9-2.4c0.6-0.4,1.3-0.6,2-0.7
	L145.8,113.1L145.8,113.1z M154.5,86.8L154.5,86.8c-2.3-1.2-4.9-1.8-7.5-1.8c-2.6,0.1-5.2,0.7-7.5,1.8l-5.2-10.4
	c-0.5-1.1-0.4-2.3,0.2-3.3c0.6-1,1.7-1.6,2.9-1.6h19.2c1.2,0,2.3,0.6,2.9,1.6c0.6,1,0.7,2.3,0.2,3.3L154.5,86.8L154.5,86.8z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconFloatItems',
  props: {
    fill: { default: '#00305a' },
  },
}
</script>
