<template>
  <svg id="Camada_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 597.87 622.78">
    <g id="elipses">
      <circle id="Elipse_11" class="cls-1" :fill="fill" cx="49.82" cy="49.82" r="49.82" />
      <circle id="Elipse_16" class="cls-1" :fill="fill" cx="49.82" cy="311.39" r="49.82" />
      <circle id="Elipse_19" class="cls-1" :fill="fill" cx="49.82" cy="572.96" r="49.82" />
      <circle id="Elipse_12" class="cls-1" :fill="fill" cx="298.94" cy="49.82" r="49.82" />
      <circle id="Elipse_14" class="cls-1" :fill="fill" cx="298.94" cy="311.39" r="49.82" />
      <circle id="Elipse_17" class="cls-1" :fill="fill" cx="298.94" cy="572.96" r="49.82" />
      <circle id="Elipse_13" class="cls-1" :fill="fill" cx="548.05" cy="49.82" r="49.82" />
      <circle id="Elipse_15" class="cls-1" :fill="fill" cx="548.05" cy="311.39" r="49.82" />
      <circle id="Elipse_18" class="cls-1" :fill="fill" cx="548.05" cy="572.96" r="49.82" />
    </g>
    <g id="elipses-2">
      <circle id="Elipse_11-2" class="cls-1" :fill="fill" cx="49.82" cy="49.82" r="49.82" />
      <circle id="Elipse_16-2" class="cls-1" :fill="fill" cx="49.82" cy="311.39" r="49.82" />
      <circle id="Elipse_19-2" class="cls-1" :fill="fill" cx="49.82" cy="572.96" r="49.82" />
      <circle id="Elipse_12-2" class="cls-1" :fill="fill" cx="298.94" cy="49.82" r="49.82" />
      <circle id="Elipse_14-2" class="cls-1" :fill="fill" cx="298.94" cy="311.39" r="49.82" />
      <circle id="Elipse_17-2" class="cls-1" :fill="fill" cx="298.94" cy="572.96" r="49.82" />
      <circle id="Elipse_13-2" class="cls-1" :fill="fill" cx="548.05" cy="49.82" r="49.82" />
      <circle id="Elipse_15-2" class="cls-1" :fill="fill" cx="548.05" cy="311.39" r="49.82" />
      <circle id="Elipse_18-2" class="cls-1" :fill="fill" cx="548.05" cy="572.96" r="49.82" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconDots',
  props: {
    fill: { default: '#00305a' },
  },
}
</script>
