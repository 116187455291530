<template>
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 85.1 85.1"
    xml:space="preserve"
  >
    <path
      id="_--icon_mundo"
      class="st0"
      :fill="fill"
      d="M42.4,0c11.3-0.1,22.2,4.4,30.1,12.6c8,7.9,12.5,18.6,12.6,29.8
	c-0.1,23.5-19.2,42.6-42.7,42.7C31.2,85,20.4,80.5,12.6,72.5C4.5,64.6-0.1,53.7,0,42.4c-0.1-11.2,4.5-22,12.6-29.8
	C20.4,4.5,31.1-0.1,42.4,0z M52,5.6c4.2,4.6,7.6,10,9.9,15.8c3.2-1.2,6.2-2.6,9.1-4.4C66,11.3,59.4,7.4,52,5.6z M73.7,20.5
	c-3.3,1.9-6.7,3.4-10.2,4.7c1.8,5.6,2.6,11.4,2.6,17.2h14.6C80.7,34.5,78.3,26.8,73.7,20.5z M80.4,46.5H66.1
	c-0.5,5.7-1.8,11.3-3.8,16.7c3.2,1.1,6.4,2.4,9.4,4.1C76.6,61.4,79.7,54.1,80.4,46.5z M68.7,70.5c-2.6-1.4-5.4-2.6-8.2-3.5
	c-2.2,4.6-5.1,8.8-8.5,12.6C58.3,78,64,74.9,68.7,70.5z M44.7,80.7c4.6-4.2,8.4-9.3,11.1-14.9c-3.6-0.9-7.4-1.4-11.1-1.5L44.7,80.7z
	 M40.3,64.3c-3.8,0.1-7.7,0.5-11.4,1.5c2.9,5.6,6.8,10.6,11.4,14.9V64.3z M44.7,59.9c4.3,0.2,8.7,0.9,12.9,2
	c2-4.9,3.2-10.2,3.5-15.5H44.7L44.7,59.9z M40.3,46.5H23.7c0.6,5.3,1.8,10.5,3.5,15.5c4.3-1.1,8.7-1.8,13.2-2V46.5z M44.7,42.4h16.7
	c0-5.3-0.8-10.7-2.3-15.8c-4.7,1.3-9.5,1.9-14.3,2V42.4z M40.3,28.6c-4.8-0.1-9.7-0.8-14.3-2c-1.7,5.1-2.4,10.4-2.3,15.8h16.7
	L40.3,28.6z M44.7,24.6c4.3-0.2,8.6-0.8,12.9-1.8c-2.9-7-7.3-13.3-12.9-18.4L44.7,24.6z M40.3,4.4c-5.6,5.1-10,11.4-12.9,18.4
	c4.2,1,8.5,1.6,12.9,1.8V4.4z M23.1,21.3c2.2-5.9,5.6-11.2,9.9-15.8c-7.3,1.8-14,5.8-19,11.4C16.9,18.8,19.9,20.2,23.1,21.3z
	 M19,42.4c0-5.8,0.9-11.7,2.6-17.2c-3.6-1.2-7-2.8-10.2-4.7c-4.7,6.1-7,13.7-7.3,21.9H19z M22.8,63.1c-2.1-5.3-3.4-10.9-3.8-16.7
	H4.4c0.8,7.7,4,14.9,9.1,20.8C16.4,65.6,19.6,64.2,22.8,63.1z M33,79.5c-3.5-3.7-6.4-7.9-8.5-12.6c-2.8,0.9-5.6,2-8.2,3.5
	C21,74.9,26.8,78,33,79.5z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconGlobe',
  props: {
    fill: { default: '#00305a' },
  },
}
</script>
