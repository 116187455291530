<template>
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 81.1 81.1"
    xml:space="preserve"
  >
    <g id="_--icon_porcentagem" transform="translate(0 -0.092)">
      <path
        id="Caminho_2124"
        class="st0"
        :fill="fill"
        d="M80,1.2c-1.5-1.5-3.9-1.5-5.4,0c0,0,0,0,0,0L1.1,74.7c-1.5,1.5-1.5,3.9,0,5.4
			c1.5,1.5,3.9,1.5,5.4,0l0,0L80,6.6C81.5,5.1,81.5,2.7,80,1.2C80,1.2,80,1.2,80,1.2z"
      />
      <path
        id="Caminho_2125"
        class="st0"
        :fill="fill"
        d="M18.1,0.1C8.1,0.1,0,8.2,0,18.2s8.1,18.1,18.1,18.1s18.1-8.1,18.1-18.1c0,0,0,0,0,0
			C36.2,8.2,28.1,0.1,18.1,0.1z M18.1,28.7c-5.8,0-10.5-4.7-10.5-10.5S12.3,7.7,18.1,7.7c5.8,0,10.5,4.7,10.5,10.5
			C28.5,24,23.9,28.7,18.1,28.7z"
      />
      <path
        id="Caminho_2126"
        class="st0"
        d="M63,45C53,45,45,53.1,45,63.1c0,10,8.1,18.1,18.1,18.1c10,0,18.1-8.1,18.1-18.1
        C81.1,53.1,73,45,63,45z M63,73.6c-5.8,0-10.5-4.7-10.5-10.5S57.3,52.6,63,52.6c5.8,0,10.5,4.7,10.5,10.5l0,0
        C73.5,68.9,68.8,73.6,63,73.6L63,73.6z"
        :fill="fill"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconPercent',
  props: {
    fill: { default: '#00305a' },
  },
}
</script>
