<template>
  <svg id="Camada_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 646.17 305.83">
    <g id="half-elipse">
      <path
        id="Caminho_14"
        class="cls-1"
        :fill="fill"
        d="m628.42,0c-9.79,168.63-154.43,297.4-323.06,287.61C150.4,278.61,26.75,154.96,17.75,0H0c9.81,178.44,162.41,315.14,340.85,305.33C505.58,296.27,637.12,164.73,646.17,0h-17.75Z"
      />
      <path
        id="Caminho_15"
        class="cls-1"
        :fill="fill"
        d="m641.43,0h-152.65c-9.79,91.51-91.91,157.77-183.42,147.98C227.36,139.64,165.73,78.01,157.39,0H2.66v17.55h137.62c19.49,100.95,117.13,166.99,218.08,147.5,74.68-14.42,133.08-72.82,147.5-147.5h135.56V0h.02Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconHalfCircle',
  props: {
    fill: { default: '#00305a' },
  },
}
</script>
