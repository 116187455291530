<template>
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 62.1 86"
    xml:space="preserve"
  >
    <g id="_--icon_calculadora" transform="translate(-1849 -2168)">
      <path
        id="Caminho_2161"
        class="st0"
        :fill="fill"
        d="M1899.2,2172.8c4,0,7.2,3.2,7.2,7.2v62.1c0,4-3.2,7.2-7.2,7.2h-38.2c-4,0-7.2-3.2-7.2-7.2
		v-62.1c0-4,3.2-7.2,7.2-7.2L1899.2,2172.8 M1899.2,2168h-38.2c-6.6,0-11.9,5.3-11.9,11.9v62.1c0,6.6,5.3,11.9,11.9,11.9h38.2
		c6.6,0,11.9-5.3,11.9-11.9v-62.1C1911.1,2173.3,1905.8,2168,1899.2,2168L1899.2,2168z"
      />
      <path
        id="Caminho_2162"
        class="st0"
        :fill="fill"
        d="M1895.6,2182.3c0.7,0,1.2,0.5,1.2,1.2v9.6c0,0.7-0.5,1.2-1.2,1.2h-31.1
		c-0.7,0-1.2-0.5-1.2-1.2v-9.6c0-0.7,0.5-1.2,1.2-1.2H1895.6 M1895.6,2177.6h-31.1c-3.3,0-6,2.7-6,6v9.6c0,3.3,2.7,6,6,6h31.1
		c3.3,0,6-2.7,6-6v-9.6C1901.6,2180.2,1898.9,2177.6,1895.6,2177.6z"
      />
      <path
        id="Caminho_2163"
        class="st0"
        :fill="fill"
        d="M1864.5,2208.6c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2
		C1863.3,2209.1,1863.9,2208.6,1864.5,2208.6 M1864.5,2203.8c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6l0,0
		C1870.5,2206.5,1867.8,2203.8,1864.5,2203.8z"
      />
      <path
        id="Caminho_2164"
        class="st0"
        :fill="fill"
        d="M1880.1,2208.6c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2
		C1878.9,2209.1,1879.4,2208.6,1880.1,2208.6 M1880.1,2203.8c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6l0,0
		C1886,2206.5,1883.4,2203.8,1880.1,2203.8z"
      />
      <path
        id="Caminho_2165"
        class="st0"
        :fill="fill"
        d="M1895.6,2208.6c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2
		C1894.4,2209.1,1894.9,2208.6,1895.6,2208.6 M1895.6,2203.8c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6l0,0
		C1901.6,2206.5,1898.9,2203.8,1895.6,2203.8z"
      />
      <path
        id="Caminho_2166"
        class="st0"
        :fill="fill"
        d="M1864.5,2222.9c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2
		C1863.3,2223.5,1863.9,2222.9,1864.5,2222.9 M1864.5,2218.2c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6l0,0
		C1870.5,2220.8,1867.8,2218.2,1864.5,2218.2z"
      />
      <path
        id="Caminho_2167"
        class="st0"
        :fill="fill"
        d="M1880.1,2222.9c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2
		C1878.9,2223.5,1879.4,2222.9,1880.1,2222.9 M1880.1,2218.2c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6l0,0
		C1886,2220.8,1883.4,2218.2,1880.1,2218.2z"
      />
      <path
        id="Caminho_2168"
        class="st0"
        :fill="fill"
        d="M1895.6,2222.9c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2
		C1894.4,2223.5,1894.9,2222.9,1895.6,2222.9 M1895.6,2218.2c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6l0,0
		C1901.6,2220.8,1898.9,2218.2,1895.6,2218.2z"
      />
      <path
        id="Caminho_2169"
        class="st0"
        :fill="fill"
        d="M1864.5,2237.3c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2
		C1863.3,2237.8,1863.9,2237.3,1864.5,2237.3 M1864.5,2232.5c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6l0,0
		C1870.5,2235.2,1867.8,2232.5,1864.5,2232.5z"
      />
      <path
        id="Caminho_2170"
        class="st0"
        :fill="fill"
        d="M1880.1,2237.3c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2
		C1878.9,2237.8,1879.4,2237.3,1880.1,2237.3 M1880.1,2232.5c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6l0,0
		C1886,2235.2,1883.4,2232.5,1880.1,2232.5z"
      />
      <path
        id="Caminho_2171"
        class="st0"
        :fill="fill"
        d="M1895.6,2237.3c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2
		C1894.4,2237.8,1894.9,2237.3,1895.6,2237.3 M1895.6,2232.5c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6l0,0
		C1901.6,2235.2,1898.9,2232.5,1895.6,2232.5z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconCalculator',
  props: {
    fill: { default: '#00305a' },
  },
}
</script>
