<template>
  <svg id="_--icon_calendario" data-name="--icon_calendario" xmlns="http://www.w3.org/2000/svg" width="58.757" height="58.756" viewBox="0 0 58.757 58.756">
    <path id="Caminho_2172" data-name="Caminho 2172" d="M60.291,12.451H52.862V9.05a2.05,2.05,0,1,0-4.1,0v3.4H23.994V9.05a2.05,2.05,0,1,0-4.1,0v3.4H12.466A5.472,5.472,0,0,0,7,17.917V60.291a5.472,5.472,0,0,0,5.466,5.466H60.291a5.472,5.472,0,0,0,5.466-5.466V17.917A5.472,5.472,0,0,0,60.291,12.451Zm-47.825,4.1h7.429v1.788a2.05,2.05,0,0,0,4.1,0V16.551h24.77v1.788a2.05,2.05,0,0,0,4.1,0V16.551h7.428a1.368,1.368,0,0,1,1.366,1.366v6.4H11.1v-6.4A1.368,1.368,0,0,1,12.466,16.551ZM60.291,61.657H12.466A1.368,1.368,0,0,1,11.1,60.291V28.413H61.657V60.291A1.368,1.368,0,0,1,60.291,61.657Z" transform="translate(-7 -7)" :fill="fill"/>
    <path id="Caminho_2173" data-name="Caminho 2173" d="M23.849,64.279h-4.1a1.366,1.366,0,0,1-1.366-1.366v-4.1a1.366,1.366,0,0,1,1.366-1.366h4.1a1.366,1.366,0,0,1,1.366,1.366v4.1A1.366,1.366,0,0,1,23.849,64.279Z" transform="translate(-10.606 -22.981)" :fill="fill"/>
    <path id="Caminho_2174" data-name="Caminho 2174" d="M41.594,64.279h-4.1a1.366,1.366,0,0,1-1.366-1.366v-4.1a1.366,1.366,0,0,1,1.366-1.366h4.1a1.366,1.366,0,0,1,1.366,1.366v4.1A1.367,1.367,0,0,1,41.594,64.279Z" transform="translate(-16.227 -22.981)" :fill="fill"/>
    <path id="Caminho_2175" data-name="Caminho 2175" d="M59.338,64.279h-4.1a1.366,1.366,0,0,1-1.366-1.366v-4.1a1.366,1.366,0,0,1,1.366-1.366h4.1A1.366,1.366,0,0,1,60.7,58.813v4.1A1.366,1.366,0,0,1,59.338,64.279Z" transform="translate(-21.848 -22.981)" :fill="fill"/>
    <path id="Caminho_2176" data-name="Caminho 2176" d="M77.083,64.279h-4.1a1.366,1.366,0,0,1-1.366-1.366v-4.1a1.366,1.366,0,0,1,1.366-1.366h4.1a1.366,1.366,0,0,1,1.366,1.366v4.1A1.366,1.366,0,0,1,77.083,64.279Z" transform="translate(-27.47 -22.981)" :fill="fill"/>
    <path id="Caminho_2177" data-name="Caminho 2177" d="M23.849,49.321h-4.1a1.366,1.366,0,0,1-1.366-1.366v-4.1a1.366,1.366,0,0,1,1.366-1.366h4.1a1.366,1.366,0,0,1,1.366,1.366v4.1A1.366,1.366,0,0,1,23.849,49.321Z" transform="translate(-10.606 -18.242)" :fill="fill"/>
    <path id="Caminho_2178" data-name="Caminho 2178" d="M41.594,49.321h-4.1a1.366,1.366,0,0,1-1.366-1.366v-4.1a1.366,1.366,0,0,1,1.366-1.366h4.1a1.366,1.366,0,0,1,1.366,1.366v4.1A1.366,1.366,0,0,1,41.594,49.321Z" transform="translate(-16.227 -18.242)" :fill="fill"/>
    <path id="Caminho_2179" data-name="Caminho 2179" d="M59.338,49.321h-4.1a1.366,1.366,0,0,1-1.366-1.366v-4.1a1.366,1.366,0,0,1,1.366-1.366h4.1A1.366,1.366,0,0,1,60.7,43.855v4.1A1.366,1.366,0,0,1,59.338,49.321Z" transform="translate(-21.848 -18.242)" :fill="fill"/>
    <path id="Caminho_2180" data-name="Caminho 2180" d="M77.083,49.321h-4.1a1.366,1.366,0,0,1-1.366-1.366v-4.1a1.366,1.366,0,0,1,1.366-1.366h4.1a1.366,1.366,0,0,1,1.366,1.366v4.1A1.366,1.366,0,0,1,77.083,49.321Z" transform="translate(-27.47 -18.242)" :fill="fill"/>
    <path id="Caminho_2181" data-name="Caminho 2181" d="M23.849,79.236h-4.1a1.366,1.366,0,0,1-1.366-1.366v-4.1A1.366,1.366,0,0,1,19.749,72.4h4.1a1.366,1.366,0,0,1,1.366,1.366v4.1A1.366,1.366,0,0,1,23.849,79.236Z" transform="translate(-10.606 -27.719)" :fill="fill"/>
    <path id="Caminho_2182" data-name="Caminho 2182" d="M41.594,79.236h-4.1a1.366,1.366,0,0,1-1.366-1.366v-4.1A1.366,1.366,0,0,1,37.494,72.4h4.1A1.366,1.366,0,0,1,42.96,73.77v4.1A1.366,1.366,0,0,1,41.594,79.236Z" transform="translate(-16.227 -27.719)" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCalendar2',
  props: {
    fill: { default: '#00305a' },
  },

}
</script>

<style>

</style>